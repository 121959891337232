import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: { name: "fulfillment.orders" },
      component: () =>
        import(/* webpackChunkName: "root" */ "@/view/layout/Layout"),
      children: [
        // menus start

        //fulfillment start
        {
          path: "/fulfillment/orders",
          name: "fulfillment.orders",
          component: () =>
            import(
              /* webpackChunkName: "orders" */ "@/own/pages/fulfillment/Orders"
            ),
        },
        {
          path: "/fulfillment/skus",
          name: "fulfillment.skus",
          component: () =>
            import(
              /* webpackChunkName: "skus" */ "@/own/pages/fulfillment/SKUs"
            ),
        },
        {
          path: "order_management/groups",
          name: "order_management.groups",
          component: () =>
            import(
              /* webpackChunkName: "skusgroup" */ "@/own/pages/orderManagement/SKUGroups"
            ),
        },
        {
          path: "/order_management/brands",
          name: "order_management.brands",
          component: () =>
            import(
              /* webpackChunkName: "skusbrands" */ "@/own/pages/orderManagement/Brands"
            ),
        },
        {
          path: "/fulfillment/inventory",
          name: "fulfillment.inventories",
          component: () =>
            import(
              /* webpackChunkName: "inventory" */ "@/own/pages/fulfillment/Inventory"
            ),
        },
        {
          path: "/fulfillment/inventory_barcode",
          name: "fulfillment.inventories_barcode",
          component: () =>
            import(
              /* webpackChunkName: "inventory_barcode" */ "@/own/pages/fulfillment/InventoryBarcode"
            ),
        },
        {
          path: "/fulfillment/inventory_new",
          name: "fulfillment.inventories_new",
          component: () =>
            import(
              /* webpackChunkName: "inventory" */ "@/own/pages/fulfillment/InventoryNew"
            ),
        },

        {
          path: "/fulfillment/suppliers",
          name: "fulfillment.suppliers",
          component: () =>
            import(
              /* webpackChunkName: "suppliers" */ "@/own/pages/fulfillment/Suppliers"
            ),
        },
        {
          path: "/fulfillment/products",
          name: "fulfillment.fulfillment_products",
          component: () =>
            import(
              /* webpackChunkName: "suppliers" */ "@/own/pages/fulfillment/Products"
            ),
        },
        // end of fulfillment
        {
          path: "/agings",
          name: "shipping.agings",
          component: () =>
            import(
              /* webpackChunkName: "aging" */ "@/own/pages/fulfillment/Aging"
            ),
        },
        {
          path: "/shipping/return_orders",
          name: "shipping.returns_management",
          component: () =>
            import(
              /* webpackChunkName: "returned" */ "@/own/pages/fulfillment/ReturnedNew"
            ),
        },
        {
          path: "/uploads",
          name: "settings.uploads",
          component: () =>
            import(
              /* webpackChunkName: "uploads" */ "@/own/pages/fulfillment/FileUpload"
            ),
        },
        {
          path: "/ai-modules",
          name: "settings.ai_modules",
          component: () =>
            import(
              /* webpackChunkName: "ai_modules" */ "@/own/pages/settings/OpenAiIntegration"
            ),
        },

        // fulfillment end

        // warehouse management start

        {
          path: "/warehouses",
          name: "warehouse_management.warehouses",
          component: () =>
            import(
              /* webpackChunkName: "warehouses" */ "@/own/pages/warehouseManagement/Warehouses"
            ),
        },
        {
          path: "/main-configuration",
          name: "warehouse_management.main_configuration",
          component: () =>
            import(
              /* webpackChunkName: "main_configuration" */ "@/own/pages/warehouseManagement/MainConfiguration"
            ),
        },
        {
          path: "/qc_checklists",
          name: "warehouse_management.qc_checklists",
          component: () =>
            import(
              /* webpackChunkName: "qc_checklists" */ "@/own/pages/warehouseManagement/QCChecklist"
            ),
        },
        {
          path: "/fulfillment_tasks",
          name: "warehouse_management.fulfillment_tasks",
          component: () =>
            import(
              /* webpackChunkName: "fulfillment_tasks" */ "@/own/pages/warehouseManagement/FulfillmentTasks"
            ),
        },
        {
          path: "/picking_assignment",
          name: "warehouse_management.picking_assignment",
          component: () =>
            import(
              /* webpackChunkName: "fulfillment_tasks" */ "@/own/pages/warehouseManagement/PickingAssignment"
            ),
        },
        {
          path: "/asn",
          name: "warehouse_management.asn",
          component: () =>
            import(/* webpackChunkName: "asn" */ "@/own/pages/fulfillment/Asn"),
        },
        {
          path: "/qc",
          name: "warehouse_management.qc",
          component: () =>
            import(/* webpackChunkName: "qc" */ "@/own/pages/fulfillment/QC"),
        },
        {
          path: "/putaway_task",
          name: "warehouse_management.putaway_tasks",
          component: () =>
            import(
              /* webpackChunkName: "putaway_tasks" */ "@/own/pages/warehouseManagement/PutawayTasks"
            ),
        },
        {
          path: "/putaway_rules",
          name: "warehouse_management.putaway_rules",
          component: () =>
            import(
              /* webpackChunkName: "putaway_rules" */ "@/own/pages/warehouseManagement/PutawayRules"
            ),
        },
        {
          path: "/receiving",
          name: "warehouse_management.receiving",
          component: () =>
            import(
              /* webpackChunkName: "receiving" */ "@/own/pages/warehouseManagement/Receiving"
            ),
        },
        {
          path: "/picking",
          name: "warehouse_management.picking",
          component: () =>
            import(
              /* webpackChunkName: "picking" */ "@/own/pages/warehouseManagement/Picking2"
            ),
        },
        {
          path: "/packing",
          name: "warehouse_management.packing",
          component: () =>
            import(
              /* webpackChunkName: "packing" */ "@/own/pages/warehouseManagement/Packing2"
            ),
        },

        {
          path: "/shipping",
          name: "warehouse_management.shipping",
          component: () =>
            import(
              /* webpackChunkName: "packing" */ "@/own/pages/warehouseManagement/Shipping"
            ),
        },
        {
          path: "/putaway_rule_based_templates",
          name: "warehouse_management.putaway_rule_based_templates",
          component: () =>
            import(
              /* webpackChunkName: "putaway_rule_based_template" */ "@/own/pages/warehouseManagement/PutawayRuleBasedTemplate"
            ),
        },
        {
          path: "/allocation_rules",
          name: "warehouse_management.allocation_rules",
          component: () =>
            import(
              /* webpackChunkName: "allocation_rules" */ "@/own/pages/warehouseManagement/AllocationRules"
            ),
        },
        {
          path: "/allocation_rule_based_templates",
          name: "warehouse_management.allocation_rule_based_templates",
          component: () =>
            import(
              /* webpackChunkName: "allocation_rule_based_template" */ "@/own/pages/warehouseManagement/AllocationRuleBasedTemplate"
            ),
        },
        {
          path: "/warehouse_management/transaction_logs",
          name: "warehouse_management.transaction_logs",
          component: () =>
            import(
              /* webpackChunkName: "transaction_logs" */ "@/own/pages/warehouseManagement/TransactionLogs"
            ),
        },
        {
          path: "/warehouse_management/inventory_balances",
          name: "warehouse_management.inventory_balances",
          component: () =>
            import(
              /* webpackChunkName: "inventory_balances" */ "@/own/pages/warehouseManagement/InventoryBalance"
            ),
        },
        {
          path: "/warehouse_management/inventory_adjustments",
          name: "warehouse_management.inventory_adjustments",
          component: () =>
            import(
              /* webpackChunkName: "inventory_adjustment" */ "@/own/pages/warehouseManagement/InventoryAdjustment"
            ),
        },
        {
          path: "/warehouse_management/location_movements",
          name: "warehouse_management.location_movements",
          component: () =>
            import(
              /* webpackChunkName: "location_movement" */ "@/own/pages/warehouseManagement/LocationMovement"
            ),
        },
        {
          path: "/warehouse_management/inventory_snapshots",
          name: "warehouse_management.inventory_snapshots",
          component: () =>
            import(
              /* webpackChunkName: "inventory_snapshots" */ "@/own/pages/warehouseManagement/InventorySnapShots"
            ),
        },
        {
          path: "/warehouse_management/warehouse_basic",
          name: "warehouse_management.warehouse_basic",
          component: () =>
            import(
              /* webpackChunkName: "warehouse_basic" */ "@/own/pages/warehouseManagement/WarehouseBasic"
            ),
        },

        {
          path: "warehouse_management/warehouse_exceptions",
          name: "warehouse_management.warehouse_exceptions",
          component: () =>
            import(
              /* webpackChunkName: "Exceptions" */ "@/own/pages/warehouseManagement/Exceptions"
            ),
        },
        // warehouse management end

        // order management
        {
          path: "/pending_audit_orders",
          name: "order_management.pending_audit_orders",
          component: () =>
            import(
              /* webpackChunkName: "pending_audit_orders" */ "@/own/pages/orderManagement/PendingAuditOrders.vue"
            ),
        },
        {
          path: "/pending_audit_returns",
          name: "order_management.pending_audit_returns",
          component: () =>
            import(
              /* webpackChunkName: "pending_audit_returns" */ "@/own/pages/orderManagement/Returned.vue"
            ),
        },
        {
          path: "/pending_confirmation_orders",
          name: "order_management.pending_confirmation_orders",
          component: () =>
            import(
              /* webpackChunkName: "pending_confirmation" */ "@/own/pages/orderManagement/PendingConfirmationOrders.vue"
            ),
        },
        {
          path: "/subscriptions",
          name: "order_management.subscriptions",
          component: () =>
            import(
              /* webpackChunkName: "subscriptions" */ "@/own/pages/orderManagement/SubscriptionManagement.vue"
            ),
        },
        {
          path: "/subscription_buyers",
          name: "order_management.subscription_buyers",
          component: () =>
            import(
              /* webpackChunkName: "subscription_buyers" */ "@/own/pages/orderManagement/SubscriptionBuyers.vue"
            ),
        },
        {
          path: "/gift_cards",
          name: "order_management.gift_cards",
          component: () =>
            import(
              /* webpackChunkName: "subscription_buyers" */ "@/own/pages/orderManagement/GiftCards.vue"
            ),
        },
        {
          path: "/order_status",
          name: "order_management.statuses",
          component: () =>
            import(
              /* webpackChunkName: "order_statuses" */ "@/own/pages/orderManagement/OrderStatuses.vue"
            ),
        },
        {
          path: "/bundles",
          name: "order_management.bundles",
          component: () =>
            import(
              /* webpackChunkName: "bundles" */ "@/own/pages/fulfillment/Bundles"
            ),
        },
        {
          path: "/order_management_rules",
          name: "order_management.order_management_rules",
          component: () =>
            import(
              /* webpackChunkName: "order_management_rules" */ "@/own/pages/orderManagement/OMSRules"
            ),
        },
        {
          path: "/warehouse_allocation_rules",
          name: "order_management.warehouse_allocation_rules",
          component: () =>
            import(
              /* webpackChunkName: "warehouse_allocation_rules" */ "@/own/pages/orderManagement/WarehouseAllocationRules"
            ),
        },
        {
          path: "/order_management_rule_logs",
          name: "order_management.oms_rule_logs",
          component: () =>
            import(
              /* webpackChunkName: "order_management_rules" */ "@/own/pages/orderManagement/OMSRuleLogs"
            ),
        },
        {
          path: "/order_management_inventories_updated",
          name: "order_management.inventories_updated",
          component: () =>
            import(
              /* webpackChunkName: "order_management_rules" */ "@/own/pages/orderManagement/InventoryUpdated"
            ),
        },
        {
          path: "/order_management/status_reasons",
          name: "order_management.status_reasons",
          component: () =>
            import(
              /* webpackChunkName: "order_management_rules" */ "@/own/pages/orderManagement/StatusReasons"
            ),
        },
        // {
        //   path: "/order_management/platform_shipping_methods",
        //   name: "order_management.platform_shipping_methods",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "shipping_method_rules" */ "@/own/pages/orderManagement/PlatformShippingMethods"
        //     ),
        // },
        // organizations start

        {
          path: "/organizations",
          names: "organization.organizations",
          component: () =>
            import(
              /* webpackChunkName: "organizations" */ "@/own/pages/organization/Organizations"
            ),
        },
        {
          path: "/bankAccounts",
          name: "organization.bankAccounts",
          component: () =>
            import(
              /* webpackChunkName: "bankAccounts" */ "@/own/pages/organization/BankAccounts"
            ),
        },
        {
          path: "/addresses",
          name: "organization.addresses",
          component: () =>
            import(
              /* webpackChunkName: "addresses" */ "@/own/pages/organization/Addresses"
            ),
        },
        {
          path: "/systemCredentials",
          name: "organization.systemCredentials",
          component: () =>
            import(
              /* webpackChunkName: "systemCredentials" */ "@/own/pages/organization/SystemCredentials"
            ),
        },
        {
          path: "/organizationActionFlow",
          name: "organization.organizationActionFlow",
          component: () =>
            import(
              /* webpackChunkName: "organizationActionFlow" */ "@/own/pages/organization/OrganizationActionFlow"
            ),
        },

        // organizations end

        //dashboard start
        // {
        //   path: "/dashboard-delivery",
        //   name: "dashboard.dashboardDelivery",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "delivery" */ "@/own/pages/dashboard/DeliveryNew"
        //     ),
        // },
        {
          path: "/dashboard-return",
          name: "dashboard.dashboardReturn",
          component: () =>
            import(
              /* webpackChunkName: "return" */ "@/own/pages/dashboard/Return"
            ),
        },
        {
          path: "/shipping_returns_extensions",
          name: "shipping.returns_extensions",
          component: () =>
            import(
              /* webpackChunkName: "clients" */ "@/own/pages/integrations/ReturnIntegrations.vue"
            ),
        },
        {
          path: "/dashboard-analytics",
          name: "dashboard.dashboardAnalytics",
          component: () =>
            import(
              /* webpackChunkName: "analitics" */ "@/own/pages/dashboard/Analitics"
            ),
        },
        {
          path: "/profile",
          name: "profile",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "@/own/pages/profile/Profile2"
            ),
        },
        //dashboard end

        //authorization start
        {
          path: "/clients",
          name: "authorization.clients",
          component: () =>
            import(
              /* webpackChunkName: "clients" */ "@/own/pages/customers/Clients"
            ),
        },
        {
          path: "/seller_addresses",
          name: "authorization.seller_addresses",
          component: () =>
            import(
              /* webpackChunkName: "SellerAddresses" */ "@/own/pages/accounts/SellerAddresses"
            ),
        },
        {
          path: "/apps",
          name: "authorization.apps",
          component: () =>
            import(/* webpackChunkName: "apps" */ "@/own/pages/customers/Apps"),
        },
        {
          path: "/roles",
          name: "authorization.roles",
          component: () =>
            import(
              /* webpackChunkName: "roles" */ "@/own/pages/accounts/Roles"
            ),
        },
        {
          path: "/accounts",
          name: "authorization.accounts",
          component: () =>
            import(
              /* webpackChunkName: "accounts" */ "@/own/pages/accounts/Accounts"
            ),
        },
        {
          path: "/users",
          name: "authorization.users",
          component: () =>
            import(
              /* webpackChunkName: "users" */ "@/own/pages/accounts/Users"
            ),
        },
        {
          path: "/carrier_accounts",
          name: "authorization.carrier_accounts",
          component: () =>
            import(
              /* webpackChunkName: "users" */ "@/own/pages/accounts/CarrierAccounts"
            ),
        },
        //authorization end

        {
          path: "/platforms",
          name: "datahub.platforms",
          component: () =>
            import(
              /* webpackChunkName: "platforms" */ "@/own/pages/datahub/Platforms"
            ),
        },
        {
          path: "/messages",
          name: "datahub.messages",
          component: () =>
            import(
              /* webpackChunkName: "messages" */ "@/own/pages/datahub/Messages"
            ),
        },
        {
          path: "/integrations",
          name: "datahub.integrations",
          component: () =>
            import(
              /* webpackChunkName: "integrations" */ "@/own/pages/datahub/Integrations"
            ),
        },
        {
          path: "/webhooks",
          name: "datahub.webhooks",
          component: () =>
            import(
              /* webpackChunkName: "webhooks" */ "@/own/pages/datahub/Webhooks"
            ),
        },
        {
          path: "/webhooks_logs",
          name: "datahub.webhooks_logs",
          component: () =>
            import(
              /* webpackChunkName: "webhooks_logs" */ "@/own/pages/datahub/WebhooksLogs"
            ),
        },
        {
          path: "/datahub_requests",
          name: "datahub.requests",
          component: () =>
            import(
              /* webpackChunkName: "webhooks_logs" */ "@/own/pages/datahub/Requests"
            ),
        },
        {
          path: "/transactions",
          name: "order_management.messagesNew",
          component: () =>
            import(
              /* webpackChunkName: "messagesNew" */ "@/own/pages/datahub/Messages2"
            ),
        },
        {
          path: "/carrierMessages",
          name: "order_management.carrierMessages",
          component: () =>
            import(
              /* webpackChunkName: "carrierMessages" */ "@/own/pages/datahub/CarrierMessages"
            ),
        },
        {
          path: "/onlineStores",
          name: "order_management.integrationsNew",
          component: () =>
            import(
              /* webpackChunkName: "integrations" */ "@/own/pages/datahub/OnlineStores"
            ),
        },

        {
          path: "/datahub",
          name: "settings.datahub",
          component: () =>
            import(
              /* webpackChunkName: "datahub" */ "@/own/pages/settings/Datahub"
            ),
        },
        {
          path: "/emails",
          name: "settings.emails",
          component: () =>
            import(
              /* webpackChunkName: "emails" */ "@/own/pages/settings/Emails"
            ),
        },
        {
          path: "/mailtemp",
          name: "settings.mailTemplate",
          component: () =>
            import(
              /* webpackChunkName: "mailtemp" */ "@/own/pages/settings/MailTemplate"
            ),
        },
        {
          path: "/email_logs",
          name: "settings.email_logs",
          component: () =>
            import(
              /* webpackChunkName: "emailLogs" */ "@/own/pages/settings/EmailLogs"
            ),
        },
        {
          path: "/service",
          name: "settings.services",
          component: () =>
            import(
              /* webpackChunkName: "service" */ "@/own/pages/settings/Service"
            ),
        },
        {
          path: "/notifications",
          name: "settings.notifications",
          component: () =>
            import(
              /* webpackChunkName: "service" */ "@/own/pages/settings/Notifications"
            ),
        },
        // finance
        {
          path: "/cod",
          name: "cod.cod",
          component: () =>
            import(/* webpackChunkName: "cod" */ "@/own/pages/finance/COD"),
        },
        {
          path: "/financeServices",
          name: "cod.finance_services",
          component: () =>
            import(
              /* webpackChunkName: "FinServices" */ "@/own/pages/finance/FinServices"
            ),
        },
        {
          path: "/currencies",
          name: "cod.currencies",
          component: () =>
            import(
              /* webpackChunkName: "FinServices" */ "@/own/pages/finance/CurrencyManagements"
            ),
        },
        {
          path: "/contracts",
          name: "cod.contracts",
          component: () =>
            import(
              /* webpackChunkName: "FinCustomer" */ "@/own/pages/finance/FinCustomer"
            ),
        },
        {
          path: "/invoices",
          name: "cod.invoices",
          component: () =>
            import(
              /* webpackChunkName: "Invoices" */ "@/own/pages/finance/Invoices"
            ),
        },
        {
          path: "/payment_types",
          name: "cod.payment_types",
          component: () =>
            import(
              /* webpackChunkName: "paymentType" */ "@/own/pages/finance/PaymentType"
            ),
        },
        {
          path: "/exchange_management",
          name: "cod.exchange_management",
          component: () =>
            import(
              /* webpackChunkName: "ExchangeManagements" */ "@/own/pages/finance/ExchangeManagements"
            ),
        },
        {
          path: "/pallet_usages",
          name: "warehouse_management.pallet_usages",
          component: () =>
            import(
              /* webpackChunkName: "pallet_usages" */ "@/own/pages/finance/PalletUsage"
            ),
        },
        // store
        {
          path: "/stores",
          name: "store.stores",
          component: () =>
            import(/* webpackChunkName: "store" */ "@/own/pages/stores/Store"),
        },
        {
          path: "/store/contacts",
          name: "store.contacts",
          component: () =>
            import(
              /* webpackChunkName: "contacts" */ "@/own/pages/stores/Contacts"
            ),
        },
        {
          path: "/store/custom",
          name: "store.pages",
          component: () =>
            import(
              /* webpackChunkName: "custom" */ "@/own/pages/stores/Custom"
            ),
        },
        {
          path: "/store/sliders",
          name: "store.sliders",
          component: () =>
            import(
              /* webpackChunkName: "sliders" */ "@/own/pages/stores/Sliders"
            ),
        },
        {
          path: "/store/categories",
          name: "store.categories",
          component: () =>
            import(
              /* webpackChunkName: "categories" */ "@/own/pages/stores/Categories"
            ),
        },
        {
          path: "/store/products",
          name: "store.products",
          component: () =>
            import(
              /* webpackChunkName: "products" */ "@/own/pages/stores/Products"
            ),
        },
        {
          path: "/store/buyers",
          name: "store.buyers",
          component: () =>
            import(
              /* webpackChunkName: "buyers" */ "@/own/pages/stores/Buyers"
            ),
        },
        {
          path: "/reviews",
          name: "store.reviews",
          component: () =>
            import(
              /* webpackChunkName: "store_reviews" */ "@/own/pages/stores/Reviews"
            ),
        },
        {
          path: "/testimonials",
          name: "store.testimonials",
          component: () =>
            import(
              /* webpackChunkName: "store_testimonials" */ "@/own/pages/stores/Testimonials"
            ),
        },
        {
          path: "/coupons",
          name: "store.coupons",
          component: () =>
            import(
              /* webpackChunkName: "store_coupons" */ "@/own/pages/stores/Coupons"
            ),
        },
        {
          path: "/payment",
          name: "store.payments",
          component: () =>
            import(
              /* webpackChunkName: "store_payments" */ "@/own/pages/stores/Payments"
            ),
        },
        {
          path: "/discovers",
          name: "store.discovers",
          component: () =>
            import(
              /* webpackChunkName: "store_discovers" */ "@/own/pages/stores/Discovers"
            ),
        },
        {
          path: "/page-builder",
          name: "store.pageBuilder",
          component: () =>
            import(
              /* webpackChunkName: "store_pageBuilder" */ "@/own/pages/stores/PageBuilder"
            ),
        },
        // storeNew
        {
          path: "/storeNew/productsNew",
          name: "storeNew.productsNew",
          component: () =>
            import(
              /* webpackChunkName: "products" */ "@/own/pages/storeNew/Products"
            ),
        },
        // shipping
        {
          path: "/shipping/couriers",
          name: "shipping.couriers",
          component: () =>
            import(
              /* webpackChunkName: "couriers" */ "@/own/pages/shipping/Couriers"
            ),
        },
        {
          path: "/shipping/courier_status",
          name: "shipping.courierStatus",
          component: () =>
            import(
              /* webpackChunkName: "courier_status" */ "@/own/pages/shipping/CourierStatus"
            ),
        },
        {
          path: "/shipping/courier_accounts",
          name: "shipping.courierAccounts",
          component: () =>
            import(
              /* webpackChunkName: "courier_accounts" */ "@/own/pages/shipping/CourierAccounts"
            ),
        },
        {
          path: "/shipping/courierAddresses",
          name: "shipping.courierAddresses",
          component: () =>
            import(
              /* webpackChunkName: "courierAddresses" */ "@/own/pages/shipping/CourierAddresses"
            ),
        },
        {
          path: "/shipping/order_status",
          name: "shipping.orderStatus",
          component: () =>
            import(
              /* webpackChunkName: "order_status" */ "@/own/pages/shipping/OrderStatus"
            ),
        },
        {
          path: "/shipping/trackingPage",
          name: "shipping.trackingPage",
          component: () =>
            import(
              /* webpackChunkName: "trackingPage" */ "@/own/pages/shipping/TrackingPage"
            ),
        },
        {
          path: "/shipping/countries",
          name: "shipping.countries",
          component: () =>
            import(
              /* webpackChunkName: "trackingPage" */ "@/own/pages/shipping/Countries"
            ),
        },
        {
          path: "/shipping/regions",
          name: "shipping.regions",
          component: () =>
            import(
              /* webpackChunkName: "ShippingRegions" */ "@/own/pages/shipping/Regions"
            ),
        },
        {
          path: "/shipping/end_customer_notifications",
          name: "shipping.end_customer_notifications",
          component: () =>
            import(
              /* webpackChunkName: "CustomerNotifications" */ "@/own/pages/shipping/CustomerNotifications"
            ),
        },
        {
          path: "/shipping/pickup_orders",
          name: "shipping.pickup_orders",
          component: () =>
            import(
              /* webpackChunkName: "PickupOrders" */ "@/own/pages/shipping/PickupOrders"
            ),
        },
        {
          path: "/shipping/courier_location_mappings",
          name: "shipping.courier_location_mappings",
          component: () =>
            import(
              /* webpackChunkName: "couriers" */ "@/own/pages/shipping/CourierAreaListMapping"
            ),
        },
        {
          path: "/shipping/courier_assignment_rules",
          name: "shipping.courier_assignment_rules",
          component: () =>
            import(
              /* webpackChunkName: "couriers" */ "@/own/pages/shipping/CourierAssignRules"
            ),
        },
        {
          path: "/shipping/courier_assignment_rule_logs",
          name: "shipping.courier_assignment_rule_logs",
          component: () =>
            import(
              /* webpackChunkName: "courierRuleLog" */ "@/own/pages/shipping/CourierRuleLogs"
            ),
        },
        // datasync
        {
          path: "/engineMonitor",
          name: "engine.engineMonitor",
          component: () =>
            import(
              /* webpackChunkName: "engineMonitor" */ "@/own/pages/datasync/Monitor"
            ),
        },
        {
          path: "/engineJobs",
          name: "engine.engineJobs",
          component: () =>
            import(
              /* webpackChunkName: "enginejobs" */ "@/own/pages/datasync/Jobs"
            ),
        },
        {
          path: "/engineScheduler",
          name: "engine.engineScheduler",
          component: () =>
            import(
              /* webpackChunkName: "enginescheduler" */ "@/own/pages/datasync/Scheduler"
            ),
        },
        // report
        {
          path: "/reports/deliveryPerformanceCountry",
          name: "reports.reportDeliveryPerformanceCountryWise",
          component: () =>
            import(
              /* webpackChunkName: "deliveryPerformanceCountry" */ "@/own/pages/reports/DeliveryPerformanceCountryWise"
            ),
        },
        {
          path: "/reports/salesWonCustomerPerMonth",
          name: "reports.reportSalesWonCustomerPerMonth",
          component: () =>
            import(
              /* webpackChunkName: "salesWonCustomerPerMonth" */ "@/own/pages/reports/WonCustomerPerMonth"
            ),
        },
        {
          path: "/reports/salesCumulativeCustomerWonPerMonth",
          name: "reports.reportSalesCumulativeCustomerWonPerMonth",
          component: () =>
            import(
              /* webpackChunkName: "CumulativeCustomersWonPerMonth" */ "@/own/pages/reports/CumulativeCustomersWonPerMonth"
            ),
        },
        {
          path: "/reports/salesUniqueActiveCustomers",
          name: "reports.reportSalesUniqueActiveCustomers",
          component: () =>
            import(
              /* webpackChunkName: "UniqueActiveCustomersPerMonth" */ "@/own/pages/reports/UniqueActiveCustomersPerMonth"
            ),
        },
        {
          path: "/reports/reportFulfilledVsDelivered",
          name: "reports.reportFulfilledVsDelivered",
          component: () =>
            import(
              /* webpackChunkName: "FulfilledvsDelivered" */ "@/own/pages/reports/FulfilledvsDelivered"
            ),
        },
        {
          path: "/reports/reportFulfilledVsShipped",
          name: "reports.reportFulfilledVsShipped",
          component: () =>
            import(
              /* webpackChunkName: "ReportFulfilledVsShipped" */ "@/own/pages/reports/ReportFulfilledVsShipped"
            ),
        },
        {
          path: "/reports/reportSalesOrders",
          name: "reports.reportSalesOrders",
          component: () =>
            import(
              /* webpackChunkName: "reportSalesOrders" */ "@/own/pages/reports/ReportSalesOrders"
            ),
        },
        {
          path: "/reports/reportClientsMovements",
          name: "reports.reportClientsMovements",
          component: () =>
            import(
              /* webpackChunkName: "reportClientsMovements" */ "@/own/pages/reports/ClientsMovements"
            ),
        },
        {
          path: "/reports/reportShippedByCustomer",
          name: "reports.reportShippedByCustomer",
          component: () =>
            import(
              /* webpackChunkName: "reportShippedByCustomer" */ "@/own/pages/reports/ReportShippedByCustomer"
            ),
        },
        {
          path: "/reports/reportShippedByCarrier",
          name: "reports.reportShippedByCarrier",
          component: () =>
            import(
              /* webpackChunkName: "reportShippedByCarrier" */ "@/own/pages/reports/ReportShippedByCarrier"
            ),
        },
        {
          path: "/reports/reportCallAgent",
          name: "reports.reportCallAgent",
          component: () =>
            import(
              /* webpackChunkName: "reportCallAgent" */ "@/own/pages/reports/ReportCallAgent"
            ),
        },
        {
          path: "/reports/countryList",
          name: "reports.countryList",
          component: () =>
            import(
              /* webpackChunkName: "countryList" */ "@/own/pages/reports/CountryList.vue"
            ),
        },
        {
          path: "/reports/reportMonthlyAverageOrders",
          name: "reports.reportMonthlyAverageOrders",
          component: () =>
            import(
              /* webpackChunkName: "reportMonthlyAverageOrders" */ "@/own/pages/reports/ReportMonthlyAverageOrders"
            ),
        },
        {
          path: "/reports/reportOrderCycle",
          name: "reports.reportOrderCycle",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderCycle" */ "@/own/pages/reports/ReportOrderCycle"
            ),
        },
        {
          path: "/reports/matrixAttemptsDelivered",
          name: "reports.matrixAttemptsDelivered",
          component: () =>
            import(
              /* webpackChunkName: "matrixAttemptsDelivered" */ "@/own/pages/reports/ReportMatrixAttemptsDelivered"
            ),
        },
        {
          path: "/reports/lastMileForwardLeg",
          name: "reports.lastMileForwardLeg",
          component: () =>
            import(
              /* webpackChunkName: "lastMileForwardLeg" */ "@/own/pages/reports/LastMileForwardLeg"
            ),
        },
        {
          path: "/reports/reportOrderReceivingTimeHeatmap",
          name: "reports.reportOrderReceivingTimeHeatmap",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderReceivingTime" */ "@/own/pages/reports/ReportOrderReceivingTime"
            ),
        },
        {
          path: "/reports/reportPortalUsageTimeHeatmap",
          name: "reports.reportPortalUsageTimeHeatmap",
          component: () =>
            import(
              /* webpackChunkName: "reportPortalUsersTime" */ "@/own/pages/reports/reportPortalUsersTime"
            ),
        },
        {
          path: "/reports/reverseLogistics",
          name: "reports.reverseLogistics",
          component: () =>
            import(
              /* webpackChunkName: "reportReverseLogistics" */ "@/own/pages/reports/ReportReverseLogistics"
            ),
        },
        {
          path: "/reports/reportSkuMovement",
          name: "reports.reportSkuMovement",
          component: () =>
            import(
              /* webpackChunkName: "reportSkuMovement" */ "@/own/pages/reports/ReportSkuMovement"
            ),
        },
        {
          path: "/reports/reportAsnSkuSerial",
          name: "reports.reportAsnSkuSerial",
          component: () =>
            import(
              /* webpackChunkName: "reportAsnSkuSerial" */ "@/own/pages/reports/ReportAsnSkuSerial"
            ),
        },
        {
          path: "/reports/reportInventory",
          name: "reports.reportInventory",
          component: () =>
            import(
              /* webpackChunkName: "reportInventory" */ "@/own/pages/reports/ReportInventory"
            ),
        },
        {
          path: "/reports/reportOrderSkuSerial",
          name: "reports.reportOrderSkuSerial",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderSkuSerial" */ "@/own/pages/reports/ReportOrderSkuSerial"
            ),
        },
        {
          path: "/reports/orderCount",
          name: "reports.orderCount",
          component: () =>
            import(
              /* webpackChunkName: "reportOrderCount" */ "@/own/pages/reports/ReportOrderCount"
            ),
        },
        {
          path: "/reports/deliveryEstimatedAccuracy",
          name: "reports.deliveryEstimatedAccuracy",
          component: () =>
            import(
              /* webpackChunkName: "deliveryEstimatedAccuracy" */ "@/own/pages/reports/DeliveryEstimatedAccuracy"
            ),
        },
        {
          path: "/reports/reportItemHistory",
          name: "reports.reportItemHistory",
          component: () =>
            import(
              /* webpackChunkName: "deliveryEstimatedAccuracy" */ "@/own/pages/reports/ItemHistory"
            ),
        },
        {
          path: "/reports/reportItemHistoryDetail",
          name: "reports.reportItemHistoryDetail",
          component: () =>
            import(
              /* webpackChunkName: "reportItemHistoryDetail" */ "@/own/pages/reports/ItemHistoryDetail.vue"
            ),
        },
        {
          path: "/reports/reportStockVariance",
          name: "reports.reportStockVariance",
          component: () =>
            import(
              /* webpackChunkName: "StockVariance" */ "@/own/pages/reports/StockVariance"
            ),
        },
        {
          path: "/reports/returnReport",
          name: "reports.returnReport",
          component: () =>
            import(
              /* webpackChunkName: "ReturnReport" */ "@/own/pages/reports/ReturnReport"
            ),
        },
        {
          path: "/reports/orderReport",
          name: "reports.orderReport",
          component: () =>
            import(
              /* webpackChunkName: "orderReport" */ "@/own/pages/reports/OrderReport"
            ),
        },
        {
          path: "/reports/reportStockHistory",
          name: "reports.reportStockHistory",
          component: () =>
            import(
              /* webpackChunkName: "orderReport" */ "@/own/pages/reports/StockHistory.vue"
            ),
        },
        // customer services
        {
          path: "/call_assignments",
          name: "customer_service.call_assignments",
          component: () =>
            import(
              /* webpackChunkName: "call_assignments" */ "@/own/pages/customerService/callAssignment"
            ),
        },
        {
          path: "/call_sheets",
          name: "customer_service.call_sheets",
          component: () =>
            import(
              /* webpackChunkName: "call_sheets" */ "@/own/pages/customerService/callSheet"
            ),
        },
        {
          path: "/ai-generator",
          name: "iqai.ai",
          component: () =>
            import(
              /* webpackChunkName: "call_sheets" */ "@/own/pages/AI-DescriptionGenerator/AI-DescriptionGenerator"
            ),
        },
      ],
    },
    {
      path: "/",
      redirect: { name: "fulfillment.orders" },
      component: () => import("@/view/layout/LayoutNew"),
      children: [
        {
          path: "/dashboard-delivery",
          name: "dashboard.dashboardDelivery",
          component: () =>
            import(
              /* webpackChunkName: "dashboardDelivery" */ "@/own/pages/dashboard/DeliveryNew"
            ),
        },
      ],
    },
    {
      path: "/",
      redirect: { name: "fulfillment.orders" },
      component: () => import("@/view/layout/LayoutNew"),
      children: [
        {
          path: "/dashboard-asn",
          name: "dashboard.dashboardAsn",
          component: () =>
            import(
              /* webpackChunkName: "DashboardAsn" */ "@/own/pages/dashboard/DashboardAsn"
            ),
        },
      ],
    },
    {
      path: "/",
      redirect: { name: "fulfillment.orders" },
      component: () => import("@/view/layout/LayoutNew"),
      children: [
        {
          path: "/dashboard-operation",
          name: "dashboard.dashboardOperation",
          component: () =>
            import(
              /* webpackChunkName: "DashboardAsn" */ "@/own/pages/dashboard/DashboardOperation"
            ),
        },
      ],
    },
    {
      path: "/",
      redirect: { name: "fulfillment.orders" },
      component: () => import("@/view/layout/LayoutNew"),
      children: [
        {
          path: "/dashboard-performance",
          name: "dashboard.dashboardPerformance",
          component: () =>
            import(
              /* webpackChunkName: "DashboardAsn" */ "@/own/pages/dashboard/DashboardPerformance"
            ),
        },
      ],
    },

    {
      name: "invoiceTemp",
      path: "/invoice_temp",
      props: true,
      component: () =>
        import(/* webpackChunkName: "invoiceTemp" */ "@/own/pages/InvoiceTemp"),
    },
    // authentication start
    {
      name: "login",
      path: "/login",
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/view/pages/auth/login_pages/Login"
        ),
    },
    {
      name: "loginNew",
      path: "/integration/login",
      component: () =>
        import(
          /* webpackChunkName: "integrationLogin" */ "@/view/pages/auth/login_pages/LoginNew"
        ),
    },
    {
      name: "registerNew",
      path: "/integration/register",
      component: () =>
        import(
          /* webpackChunkName: "integrationRegister" */ "@/view/pages/auth/RegisterNew"
        ),
    },
    {
      path: "/",
      component: () => import("@/view/layout/auth/GeneratePasswordLayout"),
      children: [
        {
          name: "forgot_password",
          path: "/forgot_password",
          component: () =>
            import(
              /* webpackChunkName: "forgotPassword" */ "@/own/pages/auth/ForgotPassword"
            ),
        },
        {
          name: "password_reset",
          path: "/password_reset",
          component: () =>
            import(
              /* webpackChunkName: "passwordReset" */ "@/own/pages/auth/PasswordReset"
            ),
        },
      ],
    },
    // authentication end
    {
      name: "maintenance",
      path: "/maintenance",
      props: true,
      component: () =>
        import(/* webpackChunkName: "maintenance" */ "@/own/pages/Maintenance"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () =>
        import(/* webpackChunkName: "404" */ "@/view/pages/error/Error-1.vue"),
    },
  ],
  mode: "history",
});
