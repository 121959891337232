import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import router from "@/router";

import store from "@/core/services/store";
import {
  SET_IMPORT_REPORT_DIALOG,
  SET_IMPORT_REPORT_DATA,
} from "@/core/services/store/config.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://localhost/";
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        if (response.status == "206") {
          store.commit(SET_IMPORT_REPORT_DATA, response.data.data);
          store.commit(SET_IMPORT_REPORT_DIALOG, true);
        }
        return response;
      },
      function (error) {
        // Do something with response error
        // if (error.response.status === 401) {
        // }
        if (error.response) {
          if (error.response.status === 503) {
            router.push({ name: "maintenance", path: "/maintenance" });
          } else if (error.response.status === 500) {
            Swal.fire({
              title: `Oops, something went wrong`,
              icon: "error",
              text: `We are sorry for the trouble. We have been notified of the error and will correct it as soon as possible. Please, try your request again in a moment.`,
            });
          } else if (error.response.status === 400) {
            if (Array.isArray(error.response.data.message)) {
              let editedItemMessage = `<div><ul style="list-style: inside;">`;
              error.response.data.message.forEach((item) => {
                editedItemMessage += `<li class="my-3 text-left">${item}</li>`;
              });
              editedItemMessage = editedItemMessage + `</ul></div>`;
              Swal.fire({
                title: `We have a little problem`,
                html: editedItemMessage,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                title: `We have a little problem`,
                icon: "error",
                text: `${error.response.data.message}`,
                showConfirmButton: true,
              });
            }
          } else if (error.response.status === 422) {
            let validationErrorMessage = `<div>The given data was invalid.</div>`;
            if (error.response.data.errors) {
              validationErrorMessage = `<div><span style="font-weight: bolder">Please, fix the following errors:</span><ul style="list-style: inside;">`;
              Object.entries(error.response.data.errors).forEach((errors) => {
                if (Array.isArray(errors[1])) {
                  errors[1].forEach((item) => {
                    validationErrorMessage += `<li><span style="font-weight: bolder">${errors[0]}:</span> ${item}</li>`;
                  });
                } else {
                  validationErrorMessage += `<li><span style="font-weight: bolder">${errors[0]}:</span> ${errors[1]}</li>`;
                }
              });
              validationErrorMessage = validationErrorMessage + `</ul></div>`;
            } else if (error.response.data?.message) {
              validationErrorMessage = `<div><span style="font-weight: bolder">Please, fix the following errors:</span><ul style="list-style: inside;">`;
              Object.entries(error.response.data.message).forEach((errors) => {
                errors[1].forEach((er) => {
                  validationErrorMessage += `<li>${er}</li>`;
                });
              });
              validationErrorMessage = validationErrorMessage + `</ul></div>`;
            }
            Swal.fire({
              title: `The change you requested was rejected`,
              icon: "error",
              html: `${validationErrorMessage}`,
            });
          } else {
            Swal.fire({
              title: `Oops, something went wrong (${error.response.status})`,
              icon: "error",
              text: `${error.response.data.message}`,
            });
          }
          // TODO: 503 redirect to maintaince page
        } else if (error.request) {
          Swal.fire({
            title: "Network Error",
            icon: "error",
            text: `Check your internet connection!`,
          });
        } else {
          Swal.fire({
            title: "Application Error",
            icon: "error",
            text: `${error.message}`,
          });
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
    Vue.axios.defaults.headers.common["Accept-Language"] = "en";
    // Vue.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    // Vue.axios.defaults.headers.common["Access-Control-Allow-Headers"] =
    // "x-requested-with";
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
    // Vue.axios.defaults.headers.common["Sec-Fetch-Mode"] = "no-cors";
    // Vue.axios.defaults.headers.common["Sec-Fetch-Site"] = "same-origin";
    // Vue.axios.defaults.headers.common["Sec-Fetch-Dest"] = "empty";

    // Vue.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async post(resource, params) {
    return await Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
